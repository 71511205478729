import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import One from "../../images/paper plane.png"
import Two from "../../images/plane.png"
import Three from "../../images/rocket.png"

import { Section, Container } from "../global"
import GatsbyImage from "gatsby-image"

const handleClick = event => {
  event.persist();
  event.preventDefault();
  console.log("clicked", event);
  var win = window.open("https://arcoinnovation.square.site/", '_blank');
  win.focus();
}

const Service= () => (
  <Section id="start">
    <StyledContainer>
      <SectionTitle>Service Plans</SectionTitle>
      <FeaturesGrid>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#one">
        <img src={One} alt="One" style={{width: "50%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Basic</FeatureTitle>
          <FeatureText>
           Included with the purhcase of hardware.
           <br></br>
           <br></br>
           <strong>Notificaiton limits:</strong>
           <br></br>
           SMS: 100
           <br></br>
           E-Mail: 1,000
           <br></br>
           HTTP Webhook: 10,000
          </FeatureText>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#two">
        <img src={Two} alt="Two" style={{width: "50%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>100 SMS bundle </FeatureTitle>
          <FeatureText>
            $9.99 CAD
           <br></br>
           <br></br>
           <strong>Adddtional notificaiton limits:</strong>
           <br></br>
           SMS: 100
           <br></br>
           E-Mail: 1,000
           <br></br>
           HTTP WebHook: 10,000
          </FeatureText>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#three">
        <img src={Three} alt="Three" style={{width: "50%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>300 SMS bundle</FeatureTitle>
          <FeatureText>
          $19.99 CAD
           <br></br>
           <br></br>
           <strong>Additional notificaiton limits:</strong>
           <br></br>
           SMS: 300
           <br></br>
           E-Mail: 3,000
           <br></br>
           API Webhook: 30,000
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
      <br></br>
      <br></br>
       Once limits are reached notificaitons will not be sent. Usage can be tracked on the Arco app under account. You can pruchase additional notifications as per the packages above.
       <Subtitle>Please feel free to contact <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> in case you have any questions.</Subtitle>
       <GetStartedContainer>
      <TryItButton onClick={handleClick}>Order Now</TryItButton>
    </GetStartedContainer>
    </StyledContainer>
    
  
      

  </Section>

)

export default Service

const StyledContainer = styled(Container)`
margin: 80px auto;`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`

const FeaturesGridHeader = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  height: 50%;
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 10px;
`

const GetStartedTitle = styled.h3`
margin: 0 auto 32px;
text-align: center;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`