import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import Service from "../components/sections/service"
import GetStarted from "../components/sections/getstarted"





const ServicePage = () => (
    <Layout>
    <SEO title="Product" />
    <Sub_Navigation />
    <Service />
  </Layout>
)


export default ServicePage